import { Box, BoxProps, Sheet, SheetProps } from '@mui/joy'

function Root(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(120px, 200px) minmax(450px, 1fr)',
          },
          gridTemplateRows: '60px 52px 1fr 48px',
          height: '100%',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

function Header(props: SheetProps) {
  return (
    <Sheet
      component="header"
      variant="solid"
      color="primary"
      invertedColors
      {...props}
      sx={[
        {
          p: 2,
          gap: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gridColumn: '1 / -1',
          gridRow: '1',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

function AppBar(props: BoxProps) {
  return (
    <Box
      component="div"
      {...props}
      sx={[
        {
          p: 2,
          bgcolor: 'background.surface',
          borderBottom: '1px solid',
          borderColor: 'divider',
          gridColumn: {
            xs: '1',
            sm: '2 / -1',
          },
          gridRow: '2',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

function Navigation(props: BoxProps) {
  return (
    <Box
      component="nav"
      {...props}
      sx={[
        {
          p: 2,
          bgcolor: 'background.surface',
          borderRight: '1px solid',
          borderColor: 'divider',
          display: {
            xs: 'none',
            sm: 'initial',
          },
          gridColumn: '1',
          gridRow: '2 / -1',
          height: '100%',
          overflowY: 'auto',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

function Main(props: BoxProps) {
  return (
    <Box
      component="main"
      {...props}
      sx={[
        {
          p: 4,
          gridColumn: { xs: '1 / -1', sm: '2 / -1' },
          gridRow: '3',
          height: '100%',
          overflowY: 'auto',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

function Footer(props: BoxProps) {
  return (
    <Box
      component="footer"
      {...props}
      sx={[
        {
          p: 2,
          bgcolor: 'background.surface',
          borderTop: '1px solid',
          borderColor: 'divider',
          gridColumn: {
            xs: '1 / -1',
            sm: '2 / -1',
          },
          gridRow: '4',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
}

const Layout = {
  Root,
  Header,
  AppBar,
  Navigation,
  Main,
  Footer,
}

export default Layout
