import { Stack, Skeleton, Box } from '@mui/joy'
import { Logs } from './Logs'

export function Navigation() {
  return (
    <Stack direction="column" gap={6}>
      <Skeleton variant="rectangular" width={80} height={25} />
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems={'center'} gap={2}>
          <Skeleton variant="circular" width={16} height={16} />
          <Skeleton variant="text" width={80} height={16} />
        </Stack>
        <Stack direction="row" alignItems={'center'} gap={2}>
          <Skeleton variant="circular" width={16} height={16} />
          <Skeleton variant="text" width={100} height={16} />
        </Stack>
        <Stack direction="row" alignItems={'center'} gap={2}>
          <Skeleton variant="circular" width={16} height={16} />
          <Skeleton variant="text" width={80} height={16} />
        </Stack>
        <Stack direction="row" alignItems={'center'} gap={2}>
          <Skeleton variant="circular" width={16} height={16} />
          <Skeleton variant="text" width={100} height={16} />
        </Stack>
      </Stack>
      <Box
        display={{
          sx: 'initial',
          xl: 'none',
        }}
      >
        <Logs />
      </Box>
    </Stack>
  )
}
