import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react'

export type EventLog = {
  timestamp: string
  message: string
}

type EventsLogContextValue = {
  eventsLog: EventLog[]
  addEventLog: (message: string, reset?: boolean) => void
}

export const EventsLogContext = createContext<EventsLogContextValue | undefined>(undefined)

type EventsLogProviderProps = {
  children: ReactNode
}

export function EventsLogProvider(props: EventsLogProviderProps) {
  const { children } = props

  const [eventsLog, setEventsLog] = useState<EventLog[]>([])

  const addEventLog = (message: string, reset?: boolean) => {
    if (reset) {
      setEventsLog((events) => [
        ...events.slice(0, 7),
        {
          timestamp: new Date().toLocaleTimeString(),
          message: 'Platform FE initializing... ↯',
        },
      ])
      return
    }
    setEventsLog((prev) => [...prev, { timestamp: new Date().toLocaleTimeString(), message }])
  }

  const value = useMemo(
    () => ({
      eventsLog,
      addEventLog,
    }),
    [eventsLog],
  )

  return <EventsLogContext.Provider value={value}>{children}</EventsLogContext.Provider>
}

export function useEventsLog() {
  const eventsLogContext = useContext(EventsLogContext)

  if (!eventsLogContext) {
    throw new Error('useEventsLog must be used within an EventsLogProvider')
  }

  return eventsLogContext
}
