import type { ThemeSpec } from './theme'

export const defaultThemeSpec: ThemeSpec = {
  palette: {
    global: {
      bgA: '#FFFFFF',
      bgB: 'rgb(245,245,245)',
      line: '#dfdfdf',
      icoA: '#262626',
      icoB: '#262626',
      icoOff: '#E0E0E0',
      errorRed: '#C94E4E',
      okGreen: '#006654',
      onDark: '#FFFFFF',
      warningYellow: '#F0BC35',
    },
    header: {
      bg: '#FFFFFF',
      title: '#000000',
      text: '#000000',
      link: '#0B78BF',
    },
    input: {
      bgA: '#FFFFFF',
      bgB: '#FFFFFF',
      stroke: '#E0E0E0',
      placeholder: '#666666',
      label: '#262626',
      value: '#2D2D2D',
      ico: '#262626',
      icoOff: '#E0E0E0',
    },
    text: {
      normal: '#262626',
      heading: '#262626',
      link: '#262626',
      subtle: '#949494',
      off: '#949494',
    },
    button: {
      bgA: '#A8DBFF',
      bgB: '#F5F5F5',
      bgC: '#6699CC',
      labelA: '#262626',
      labelB: '#262626',
      labelC: '#FFFFFF',
    },
  },
  fonts: {
    body: 'Söhne, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    title:
      'Söhne, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  borders: {
    radii: {
      input: '10px',
      button: '10px',
    },
  },
  shadows: {
    input: 'none',
    button: 'none',
  },
}
