import { ReactNode, useEffect, useState } from 'react'
import axios from 'axios'
import { CssVarsProvider, CssBaseline, GlobalStyles, Theme } from '@mui/joy'
import { config } from '../config'
import { useSession } from './SessionProvider'
import { ThemeSpec, createThemeAndStyles } from '../utils/theme'
import { defaultThemeSpec } from '../utils/defaultTheme'

type ThemeProps = {
  children: ReactNode
}

export function ThemeProvider(props: ThemeProps) {
  const { children } = props

  const { platformId } = useSession()
  const [themeAndStyles, setThemeAndStyles] = useState<{ theme: Theme; styles: string }>()

  useEffect(() => {
    axios
      .get<ThemeSpec>(`${config.THEME_URL}/${platformId}.json`)
      .then(({ data }) => {
        setThemeAndStyles(createThemeAndStyles(data))
      })
      .catch(() => {
        console.error('Failed to load the theme')
        /* fallback to default theme */
        setThemeAndStyles(createThemeAndStyles(defaultThemeSpec))
      })
  }, [platformId])

  if (!themeAndStyles) {
    return null
  }

  const { theme, styles } = themeAndStyles

  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={styles} />
      {children}
    </CssVarsProvider>
  )
}
