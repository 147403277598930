import './App.css'
import Layout from './components/Layout'
import { Navigation } from './components/Navigation'
import { AppBar } from './components/AppBar'
import { Main } from './components/Main'
import { ThemeProvider } from './providers/Theme'
import { EventsLogProvider } from './providers/EventsLog'
import { Header } from './components/Header'
import { config } from './config'
import { Iframe } from './components/Iframe'
import { SessionProvider } from './providers/SessionProvider'
import { ApiProvider } from './providers/ApiProvider'
import { CommandMenu } from './components/CommandMenu'

function App() {
  return (
    <ApiProvider>
      <EventsLogProvider>
        <SessionProvider>
          <ThemeProvider>
            {config.widgetOnlyMode ? (
              <Iframe />
            ) : (
              <Layout.Root>
                <Layout.Header>
                  <Header />
                </Layout.Header>
                <Layout.Navigation>
                  <Navigation />
                </Layout.Navigation>
                <Layout.AppBar>
                  <AppBar />
                </Layout.AppBar>
                <Layout.Main>
                  <Main />
                </Layout.Main>
                <Layout.Footer />
              </Layout.Root>
            )}
          </ThemeProvider>
        </SessionProvider>
        <CommandMenu />
      </EventsLogProvider>
    </ApiProvider>
  )
}

export default App
