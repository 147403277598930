import { v4 as uuidv4 } from 'uuid'

const URLS = {
  DEV: {
    BANXWARE_IFRAME_DOMAIN: process.env.REACT_APP_BANXWARE_IFRAME_DOMAIN ?? 'https://app.dev.banxware.io',
    MOCK_PLATFORM_API_BASE_URL:
      process.env.REACT_APP_MOCK_PLATFORM_API_BASE_URL ?? 'https://mock-platform-api.dev.banxware.io',
    THEME_URL: process.env.THEME_URL ?? 'https://elf-platform-api-themes-dev.s3.eu-central-1.amazonaws.com',
  },
  STG: {
    BANXWARE_IFRAME_DOMAIN: 'https://app.stg.banxware.io',
    MOCK_PLATFORM_API_BASE_URL: 'https://mock-platform-api.stg.banxware.io',
    THEME_URL: process.env.THEME_URL ?? 'https://elf-platform-api-themes-stg.s3.eu-central-1.amazonaws.com',
  },
}

const isDevOrLocal = window.location.origin.includes('localhost') || window.location.origin.includes('dev')
const env = isDevOrLocal ? 'DEV' : 'STG'

// Provide a query param of `?platformMerchantId=#############################` to load an existing merchant
const platformMerchantId = new URLSearchParams(window?.location?.search)?.get('platformMerchantId') || uuidv4()

// Provide a query param of `?apiKey=#############################` to load an existing platform
const apiKey = new URLSearchParams(window?.location?.search)?.get('apiKey') ?? undefined

// Provide a query param of `?monthlyRevenue=#########` to provide average monthly revenue for the merchant
const monthlyRevenueParam = new URLSearchParams(window?.location?.search)?.get('monthlyRevenue')
const monthlyRevenue = env === 'STG' && !monthlyRevenueParam ? '5600000' : monthlyRevenueParam ?? undefined

// Provide a query param of `?locale=##-##` to provide platform's locale
const locale = new URLSearchParams(window?.location?.search)?.get('locale') || 'en-GB'

// Provide a query param of `?populateMerchantData=true` to receive mock merchant data from the platform
const populateMerchantDataParam = new URLSearchParams(window?.location?.search)?.get('populateMerchantData')
const populateMerchantData = env === 'STG' && !populateMerchantDataParam ? true : populateMerchantDataParam === 'true'

// Provide a query param of `?populateBankAccounts=######` to receive mock bank accounts from the platform ("single" or "multi")
const populateBankAccounts = new URLSearchParams(window?.location?.search)?.get('populateBankAccounts') || 'false'

// Provide a query with `?populatePrequalificationData=###` (all, some, or none) to send mock pre-qualification data on initialization
const populatePrequalificationData =
  new URLSearchParams(window?.location?.search)?.get('populatePrequalificationData') || 'all'

// Provide a query param of `?mode=widget` to enable widget-only mode
const widgetOnlyMode = new URLSearchParams(window?.location?.search)?.get('mode') === 'widget'

export const config = {
  ...URLS[env],
  platformMerchantId,
  apiKey,
  monthlyRevenue,
  locale,
  populateMerchantData,
  populateBankAccounts,
  populatePrequalificationData,
  widgetOnlyMode,
}
