import { Sheet } from '@mui/joy'
import { config } from '../config'
import { useSession } from '../providers/SessionProvider'
import { useInitializeIframe } from '../hooks/useInitializeIframe'

export function Iframe() {
  const { platformId } = useSession()

  useInitializeIframe()

  return (
    <Sheet
      component="iframe"
      id="banxware"
      allow="clipboard-write"
      title="demo"
      variant="outlined"
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 'lg',
        margin: 0,
        padding: 0,
      }}
      src={platformId && `${config.BANXWARE_IFRAME_DOMAIN}?platformId=${platformId}`}
    />
  )
}
