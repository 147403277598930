import { ReactNode, createContext, useContext } from 'react'
import { CircularProgress, Sheet, styled } from '@mui/joy'
import { useInitializeSession } from '../hooks/useInitializeSession'
import { InitializeBanxwareResponse } from '../services/mock-backend-client'

type AuthProviderProps = {
  children: ReactNode
}

const Root = styled(Sheet)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

type SessionContextValue = InitializeBanxwareResponse

export const SessionContext = createContext<SessionContextValue | undefined>(undefined)

export function SessionProvider(props: AuthProviderProps) {
  const { children } = props

  const { session, isInitialLoading, error } = useInitializeSession()

  if (error) {
    return <Root>Error: {error.message}</Root>
  }

  if (!session || isInitialLoading) {
    return (
      <Root>
        <CircularProgress />
      </Root>
    )
  }

  return <SessionContext.Provider value={{ ...session }}>{children}</SessionContext.Provider>
}

export function useSession() {
  const session = useContext(SessionContext)
  if (!session) {
    throw new Error('useSession must be used within an SessionProvider')
  }
  return session
}
