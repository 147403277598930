import { Stack, Skeleton } from '@mui/joy'

export function AppBar() {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Skeleton variant="text" width={80} height={16} />
        <Skeleton variant="text" width={80} height={16} />
        <Skeleton
          variant="text"
          width={80}
          height={16}
          sx={{
            display: { xs: 'none', md: 'initial' },
          }}
        />
        <Skeleton
          variant="text"
          width={80}
          height={16}
          sx={{
            display: { xs: 'none', md: 'initial' },
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Skeleton variant="circular" width={16} height={16} />
        <Skeleton variant="text" width={80} height={16} />
      </Stack>
    </Stack>
  )
}
