export function addToUrl(params: Record<string, string>) {
  const urlParams = new URLSearchParams(window.location.search)

  Object.keys(params).forEach((key) => {
    const value = params[key]
    if (value !== '') {
      urlParams.set(key, value)
    } else {
      urlParams.delete(key)
    }
  })

  const queryString = urlParams.toString()
  const newUrl = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname

  window.history.replaceState(null, '', newUrl)
}

const queryParams = new URLSearchParams(window.location.search)

export function getFromUrl(key: string) {
  const value = queryParams.get(key)
  if (value === null) return ''
  return value
}

export function getBooleanFromUrl(key: string) {
  const value = queryParams.get(key)
  if (value === null) return 'false'
  return value
}

export function convertToFloat(value: string) {
  return (parseFloat(value.replace(/,|€/g, '')) * 100).toString()
}

export function convertToDecimals(value: string) {
  return (parseFloat(value) / 100).toFixed(2)
}
