import {
  Modal,
  ModalDialog,
  ModalClose,
  Typography,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Box,
} from '@mui/joy'
import React, { FormEvent } from 'react'
import { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { config } from '../config'
import { NumericFormatAdapter } from './NumericFormatAdapter'
import { addToUrl, convertToDecimals, getFromUrl, convertToFloat, getBooleanFromUrl } from '../utils'

export function CommandMenu() {
  const [open, setOpen] = useState(false)

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)

    return () => document.removeEventListener('keydown', down)
  }, [])

  // Toggle the menu when ⌘K is pressed in iframe
  useEffect(() => {
    const onOpenCommandMenuEventHandler = (event: MessageEvent) => {
      if (event.origin !== config.BANXWARE_IFRAME_DOMAIN) return

      try {
        const message = JSON.parse(event.data)
        if (message?.op === 'openCommandMenu') {
          setOpen((open) => !open)
        }
      } catch (e) {
        // ignore non-banxware iframe message.
      }
    }

    window.addEventListener('message', onOpenCommandMenuEventHandler)

    return () => {
      window.removeEventListener('message', onOpenCommandMenuEventHandler)
    }
  }, [])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const values = Object.fromEntries(formData.entries()) as Record<string, string>

    if (values.monthlyRevenue !== '') {
      values.monthlyRevenue = convertToFloat(values.monthlyRevenue)
    }

    addToUrl(values)

    window.location.reload()
  }

  return (
    <Transition in={open} timeout={400}>
      {(state: string) => (
        <Modal
          keepMounted
          open={!['exited', 'exiting'].includes(state)}
          onClose={() => setOpen(false)}
          sx={{
            visibility: state === 'exited' ? 'hidden' : 'visible',
          }}
          slotProps={{
            backdrop: {
              sx: {
                opacity: 0,
                backdropFilter: 'none',
                transition: `opacity 400ms, backdrop-filter 400ms`,
                ...{
                  entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                  entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                }[state],
              },
            },
          }}
        >
          <ModalDialog
            size="lg"
            sx={(theme) => ({
              opacity: 0,
              transition: `opacity 300ms`,
              ...{
                entering: { opacity: 1 },
                entered: { opacity: 1 },
              }[state],
              [theme.breakpoints.only('xs')]: {
                top: 'unset',
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: 'none',
                maxWidth: 'unset',
              },
            })}
          >
            <ModalClose />
            <Typography level="h2" my={2}>
              Session Preferences
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: 'minmax(200px, 300px) minmax(200px, 300px) ',
                },
              }}
            >
              <FormControl>
                <FormLabel>API Key</FormLabel>
                <Input name="apiKey" defaultValue={getFromUrl('apiKey')} />
              </FormControl>
              <FormControl>
                <FormLabel>Monthly revenue</FormLabel>
                <Input
                  name="monthlyRevenue"
                  defaultValue={convertToDecimals(getFromUrl('monthlyRevenue'))}
                  slotProps={{
                    input: {
                      component: NumericFormatAdapter,
                    },
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Populate merchant data</FormLabel>
                <Select name="populateMerchantData" defaultValue={getBooleanFromUrl('populateMerchantData')}>
                  <Option value="true">Yes</Option>
                  <Option value="false">No</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Populate bank accounts</FormLabel>
                <Select name="populateBankAccounts" defaultValue={getFromUrl('populateBankAccounts')}>
                  <Option value="single">Single</Option>
                  <Option value="multi">Multiple</Option>
                  <Option value="">No</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Populate prequalification data</FormLabel>
                <Select name="populatePrequalificationData" defaultValue={getFromUrl('populatePrequalificationData')}>
                  <Option value="none">None</Option>
                  <Option value="some">Some</Option>
                  <Option value="">All</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Locale (ONLY widget)</FormLabel>
                <Select name="locale" defaultValue={getFromUrl('locale')}>
                  <Option value="">EN</Option>
                  <Option value="de-DE">DE</Option>
                  <Option value="nl-NL">NL</Option>
                </Select>
              </FormControl>
              <Box sx={{ gridColumn: '1 / -1', display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <Typography variant="soft" color="danger" level="body-sm" textAlign="center">
                  SUBMITTING THESE CHANGES WILL RESTART YOUR SESSION
                </Typography>
                <Button type="submit" fullWidth>
                  Submit
                </Button>
              </Box>
            </Box>
          </ModalDialog>
        </Modal>
      )}
    </Transition>
  )
}
