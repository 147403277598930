import { Box, Sheet } from '@mui/joy'
import { Logs } from './Logs'
import { Iframe } from './Iframe'

export function Main() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: {
          sx: '1fr',
          xl: 'minmax(450px, 1fr) minmax(200px, 270px)',
        },
        gap: 4,
      }}
    >
      <Iframe />
      <Sheet
        variant="outlined"
        sx={{
          height: 'fit-content',
          maxHeight: '100%',
          overflowY: 'auto',
          borderRadius: 'lg',
          p: 2,
          display: {
            xs: 'none',
            xl: 'initial',
          },
        }}
      >
        <Logs />
      </Sheet>
    </Box>
  )
}
