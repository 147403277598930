import { extendTheme } from '@mui/joy/styles'

type FontFormat = 'woff' | 'woff2' | 'truetype' | 'opentype' | 'embedded-opentype' | 'svg'

export type Font = {
  fontFamily: string
  fontWeight?: string
  url?: string
  format?: FontFormat
}
export type RemoteFont = Required<Font>

function isRemoteFont(font: string | Font): font is RemoteFont {
  return typeof font === 'object' && 'fontFamily' in font && 'fontWeight' in font && 'format' in font && 'url' in font
}

function standardizeFont(font: string | RemoteFont): Font {
  if (isRemoteFont(font)) {
    return font
  }

  return {
    fontFamily: font,
  }
}

function convertRemoteFontToFontFace(font: RemoteFont): string {
  return `
    @font-face {
      font-family: ${font.fontFamily};
      font-style: normal;
      font-weight: ${font.fontWeight};
      font-display: block;
      src: url(${font.url}) format(${font.format});
    }
  `
}

export type ThemeSpec = {
  palette: {
    global: {
      bgA: string
      bgB: string
      line: string
      icoA: string
      icoB: string
      icoOff: string
      errorRed: string
      okGreen: string
      onDark: string
      warningYellow: string
    }
    header: {
      bg: string
      title: string
      text: string
      link: string
    }
    input: {
      bgA: string
      bgB: string
      stroke: string
      placeholder: string
      label: string
      value: string
      ico: string
      icoOff: string
    }
    text: {
      normal: string
      heading: string
      link: string
      subtle: string
      off: string
    }
    button: {
      bgA: string
      bgB: string
      bgC: string
      labelA: string
      labelB: string
      labelC: string
    }
  }
  fonts: {
    body: string | RemoteFont
    title: string | RemoteFont
  }
  borders: {
    radii: {
      input: string
      button: string
    }
  }
  shadows: {
    input: string
    button: string
  }
}

export const FONT_FALLBACK =
  ", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"

export function createThemeAndStyles(themeSpec: ThemeSpec) {
  const { fonts, palette } = themeSpec

  const fontTitle = standardizeFont(fonts.title)
  const fontBody = standardizeFont(fonts.body)

  const theme = extendTheme({
    cssVarPrefix: 'bxw',
    fontFamily: {
      display: fontTitle.fontFamily + FONT_FALLBACK,
      body: fontBody.fontFamily + FONT_FALLBACK,
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            solidBg: palette.global.icoA,
          },
          background: {
            surface: palette.global.bgA,
          },
        },
      },
    },
    components: {
      JoySkeleton: {
        defaultProps: {
          animation: false,
        },
      },
    },
  })

  const remoteFonts = [fontTitle, fontBody].filter(isRemoteFont)
  const styles = remoteFonts.map(convertRemoteFontToFontFace).join('')

  return { theme, styles }
}
