import { useState } from 'react'
import { Box, Typography, Divider, IconButton } from '@mui/joy'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useEventsLog } from '../providers/EventsLog'

export function Logs() {
  const [showLogs, setShowLogs] = useState(true)

  const { eventsLog } = useEventsLog()

  const toggleLogs = () => {
    setShowLogs((prev) => !prev)
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography level="title-sm">Logs</Typography>
        <IconButton size="sm" onClick={toggleLogs}>
          {showLogs ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>

      {showLogs && (
        <>
          <Divider sx={{ my: 2 }} />
          {eventsLog.map((event, index) => (
            <Typography
              key={index}
              level="body-xs"
              sx={{
                overflowWrap: 'break-word',
                mt: 0.5,
              }}
            >{`${event.timestamp}: ${event.message}`}</Typography>
          ))}
        </>
      )}
    </>
  )
}
