import { useCallback, useEffect, useState } from 'react'
import { config } from '../config'
import { useQuery } from '@tanstack/react-query'
import { notifyConsent } from '../services/mock-backend-client'
import { useEventsLog } from '../providers/EventsLog'
import { useSession } from '../providers/SessionProvider'

export function useInitializeIframe() {
  const { addEventLog } = useEventsLog()
  const session = useSession()

  const [consentMerchantId, setConsentMerchantId] = useState<string>()

  const initializeIframe = useCallback(() => {
    const banxwareIFrame = document.getElementById('banxware')

    if (!banxwareIFrame || !(banxwareIFrame instanceof HTMLIFrameElement)) {
      addEventLog(`Unable to get access banxware iframe element.`)
      return
    }

    if (!banxwareIFrame.contentWindow) {
      addEventLog(`Unable to get access banxware iframe Window object.`)
      return
    }

    // preserve first 7 events that belong to the /banxware/initialize query
    addEventLog('Platform FE initializing... ↯', true)

    // Post initialization message to banxware iframe
    const message = JSON.stringify({
      op: 'initialize',
      sessionData: session,
    })
    console.log(`Posting message [${message}] to `, config.BANXWARE_IFRAME_DOMAIN)
    addEventLog(`Initialize Banxware iFrame ${config.BANXWARE_IFRAME_DOMAIN}`)
    banxwareIFrame.contentWindow.postMessage(message, config.BANXWARE_IFRAME_DOMAIN)
  }, [session, addEventLog])

  useEffect(() => {
    // listen to iframe initialization requests - it can be triggered more than once in development
    const onReadyEventHandler = (event: MessageEvent<string>) => {
      if (event.origin !== config.BANXWARE_IFRAME_DOMAIN) return
      try {
        const message = JSON.parse(event.data)
        if (message.op === 'ready') {
          addEventLog(`Mock platform received a signal that Banxware iFrame is ready for initialization`)
          initializeIframe()
        }
      } catch (e) {
        // ignore non-banxware iframe message.
      }
    }

    // Add the listener on the window to pickup events
    window.addEventListener('message', onReadyEventHandler)

    return () => {
      window.removeEventListener('message', onReadyEventHandler)
    }
  }, [initializeIframe, addEventLog])

  useEffect(() => {
    // Define what happens when we receive the consent event from the Banxware iframe.
    const onConsentEventHandler = (event: MessageEvent<any>) => {
      if (event.origin !== config.BANXWARE_IFRAME_DOMAIN) return
      try {
        const message = JSON.parse(event.data)
        if (message.op === 'merchant-consent') {
          addEventLog(`Mock platform received merchant consent 😎`)
          addEventLog(`iFrame message: ${event.data}`)
          addEventLog(`For platformMerchantId: ${message.platformMerchantId}`)
          // Set the merchantId that has given consent
          setConsentMerchantId(message.platformMerchantId)
          // Remove consent event listener
          window.removeEventListener('message', onConsentEventHandler)
        }
      } catch (e) {
        // ignore non-banxware iframe message.
      }
    }
    window.addEventListener('message', onConsentEventHandler)
    return () => {
      return window.removeEventListener('message', onConsentEventHandler)
    }
  }, [addEventLog])

  // Run notifyConsent call once we receive the consentMerchantId
  const { error, isError, isSuccess } = useQuery({
    queryKey: ['notifyConsent'],
    queryFn: () =>
      notifyConsent({
        platformMerchantId: consentMerchantId!,
        apiKey: config.apiKey,
        populateMerchantData: config.populateMerchantData,
        populateBankAccounts: config.populateBankAccounts,
      }),
    retry: 3,
    enabled: !!consentMerchantId,
  })

  useEffect(() => {
    if (isSuccess) {
      addEventLog(`Banxware notified of merchant consent ✅ 🎉`)
    } else if (isError) {
      addEventLog(JSON.stringify(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError, error])
}
