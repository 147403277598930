import axios from 'axios'
import { config } from '../config'

const baseURL = config.MOCK_PLATFORM_API_BASE_URL
const client = axios.create({ baseURL, timeout: 20000 })

export type InitializeBanxwareRequest = {
  apiKey: string | undefined
  platformMerchantId: string | undefined
  monthlyRevenue: string | undefined
  locale: string
  populatePrequalificationData: string
}

type BanxwareIndustryClassification = 'restaurant' | 'e-commerce' | 'stationary-retail' | 'other'

type LegalForm = 'gmbh' | 'gmbh&cokg' | 'ek' | 'freelancer' | 'ug' | 'gbr' | 'soleproprietor'

export type InitializeBanxwareResponse = {
  sessionToken: string
  merchantId: string
  platformMerchantId: string
  platformId: string
  platformTag: string
  locale: string
  banxwareIndustryClassification: BanxwareIndustryClassification
  // this props are marked as optional because it is up to the platform providing them - meant for the pre-eligibility page
  legalForm?: LegalForm
  foundationDate?: string
}

export const initializeBanxware = async (req: InitializeBanxwareRequest) => {
  const searchParams = new URLSearchParams()
  Object.entries(req).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.append(key, value)
    }
  })

  const queryString = searchParams.toString()
  const url = queryString ? `/banxware/initialize?${queryString}` : '/banxware/initialize'

  try {
    const res = await client.get<InitializeBanxwareResponse>(url)
    return res.data
  } catch (e: any) {
    console.error('Failed to initialize banxware session.', e)
    throw new Error('Failed to initialize session.')
  }
}

type NotifyConsentRequest = {
  platformMerchantId: string
  apiKey: string | undefined
  populateMerchantData: boolean
  populateBankAccounts: string
}

export const notifyConsent = async (req: NotifyConsentRequest) => {
  try {
    const res = await client.post(`/banxware/consent`, req)
    return res.data
  } catch (e: any) {
    console.error('Failed to send consent to banxware.')
    if (e?.response?.data) {
      try {
        console.log(JSON.parse(e?.response?.data))
      } catch (jsonError) {
        console.log(e?.response?.data)
      }
    }
    return undefined
  }
}
