import { useQuery } from '@tanstack/react-query'
import { config } from '../config'
import { initializeBanxware } from '../services/mock-backend-client'
import { useEventsLog } from '../providers/EventsLog'
import { useEffect } from 'react'

const apiKey = config.apiKey

export function useInitializeSession() {
  const { addEventLog } = useEventsLog()

  const {
    data: session,
    isInitialLoading,
    error,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['initBanxware', { apiKey }],
    queryFn: () => {
      addEventLog(`PlatformMerchantId: ${config.platformMerchantId}`)
      addEventLog(`monthlyRevenue: ${config.monthlyRevenue}`)
      addEventLog(`locale: ${config.locale}`)
      addEventLog(`populatePrequalificationData: ${config.populatePrequalificationData}`)

      return initializeBanxware({
        apiKey,
        platformMerchantId: config.platformMerchantId,
        monthlyRevenue: config.monthlyRevenue,
        locale: config.locale,
        populatePrequalificationData: config.populatePrequalificationData,
      })
    },
  })

  const merchantId = session?.merchantId
  const sessionToken = session?.sessionToken?.substring(0, 8)

  useEffect(() => {
    if (isSuccess) {
      addEventLog(`Banxware Session created!  ✅ 🎉 `)
      addEventLog(`token: ${sessionToken}...`)
      addEventLog(`merchantId: ${merchantId}`)
    } else if (isError) {
      addEventLog(JSON.stringify(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, apiKey, sessionToken, merchantId, isError, error])

  return { session, isInitialLoading, error }
}
